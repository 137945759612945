td.tData.css-xumdn4 {
    display: table-cell;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 400;
}

th.css-1zts0j {
    display: table-cell;
    align-items: center;
    justify-content: center;
    text-align: center;
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 4px #87BDEA;
	border-radius: 8px;
	background-color: #F5F5F5;
    height: 8px;
}

#style-1::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
    height: 8px;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 8px;
    height: 8px;
	-webkit-box-shadow: inset 0 0 4px #87BDEA;
	background-color: #555;
}